import wasmFeatureDetect from '../common/detectWasmFeatures';
import JsMediaSDK_WaterMarkRGBA from '../inside/JsMediaSDK_WaterMark';
import globalTracingLogger from '../common/globalTracingLogger';
import jsMediaEngineVariables from '../inside/JsMediaEngine_Variables';

const downloadUrlMapPromise = {};

const getBrowserVersionInfo = (function () {
  let broswerinfo = (() => {
    var Sys = {};
    var ua = navigator.userAgent.toLowerCase();
    var s;
    (s = ua.match(/rv:([\d.]+)\) like gecko/))
      ? (Sys.ie = s[1])
      : (s = ua.match(/msie ([\d\.]+)/))
      ? (Sys.ie = s[1])
      : (s = ua.match(/edge\/([\d\.]+)/))
      ? (Sys.edge = s[1])
      : (s = ua.match(/firefox\/([\d\.]+)/))
      ? (Sys.firefox = s[1])
      : (s = ua.match(/(?:opera|opr).([\d\.]+)/))
      ? (Sys.opera = s[1])
      : (s = ua.match(/chrome\/([\d\.]+)/))
      ? (Sys.chrome = s[1])
      : (s = ua.match(/version\/([\d\.]+).*safari/))
      ? (Sys.safari = s[1])
      : 0;
    return Sys;
  })();

  return function () {
    return broswerinfo;
  };
})();

/**
 * @description if broswer is chrome and broswer version higher than a target version
 */
const isChromeVersionHigherThan = (version = 90) => {
  var Sys = getBrowserVersionInfo();
  if (Sys.chrome) {
    var chromeVersion = parseInt(Sys.chrome);
    if (chromeVersion >= version) {
      return true;
    }
  }
  return false;
};

/**
 * @description if broswer is chrome and broswer version higher than a target version
 */

const isFirefoxVersionHigherThan = (version) => {
  var Sys = getBrowserVersionInfo();
  if (Sys.firefox) {
    var firefoxVersion = parseInt(Sys.firefox);
    if (firefoxVersion >= version) {
      return true;
    }
  }
  return false;
};

//Edge us edg as userAgent
const isChromimuVersionHigherThan = (version = 90) => {
  var Sys = {};
  var ua = navigator.userAgent.toLowerCase();
  var s;
  (s = ua.match(/rv:([\d.]+)\) like gecko/))
    ? (Sys.ie = s[1])
    : (s = ua.match(/msie ([\d\.]+)/))
    ? (Sys.ie = s[1])
    : (s = ua.match(/edge?\/([\d\.]+)/))
    ? (Sys.edg = s[1])
    : (s = ua.match(/firefox\/([\d\.]+)/))
    ? (Sys.firefox = s[1])
    : (s = ua.match(/(?:opera|opr).([\d\.]+)/))
    ? (Sys.opera = s[1])
    : (s = ua.match(/chrome\/([\d\.]+)/))
    ? (Sys.chrome = s[1])
    : (s = ua.match(/version\/([\d\.]+).*safari/))
    ? (Sys.safari = s[1])
    : 0;

  if (Sys.chrome || Sys.edg || Sys.opera) {
    var chromimuVersion = parseInt(Sys.chrome || Sys.edg || Sys.opera);
    if (chromimuVersion >= version) {
      return true;
    }
  }
  return false;
};

function isSupportMediaStreamTrackProcessor() {
  return (
    typeof MediaStreamTrackProcessor === 'function' &&
    IsSupportWebGLOffscreenCanvas()
  );
}

class _ApiSupportUtility {
  constructor() {
    this._isSupportMultiThread = false;
    this._isSupportSIMD = false;
    this.inProgressPromise = {
      checkSupportMultiThread: null,
      checkSupportSIMD: null,
    };
    /** webtransport switch */
    this._isSupportWebtransport = false;
    /** virtual background switch */
    this._isSupportVirtualBackground = false;
  }

  async checkIsSupportMultiThread() {
    if (this.getIsSupportMultiThread()) return;
    try {
      if (this.inProgressPromise.checkSupportMultiThread) {
        await this.inProgressPromise.checkSupportMultiThread;
      } else {
        this.inProgressPromise.checkSupportMultiThread =
          wasmFeatureDetect.threads();
        this._setIsSupportMultiThread(
          await this.inProgressPromise.checkSupportMultiThread
        );
      }
    } catch (e) {
      this._setIsSupportMultiThread(false);
    }
  }

  _setIsSupportMultiThread(bool) {
    this._isSupportMultiThread = bool;
  }

  getIsSupportMultiThread() {
    return this._isSupportMultiThread;
  }

  async checkIsSupportSIMD() {
    if (this.getIsSupportSIMD()) return;
    try {
      if (this.inProgressPromise.checkSupportSIMD) {
        await this.inProgressPromise.checkSupportSIMD;
      } else {
        this.inProgressPromise.checkSupportSIMD = wasmFeatureDetect.simd();
        this._setIsSupportSIMD(await this.inProgressPromise.checkSupportSIMD);
      }
    } catch (e) {
      this._setIsSupportSIMD(false);
    }
  }

  _setIsSupportSIMD(bool) {
    this._isSupportSIMD = bool;
  }

  getIsSupportSIMD() {
    return this._isSupportSIMD;
  }

  getIsSupportWebtransport() {
    return (
      isChromeVersionHigherThan(97) &&
      typeof WebTransport === 'function' &&
      this._isSupportWebtransport
    );
  }

  setIsSupportWebtransport(bool) {
    this._isSupportWebtransport = !!bool;
  }

  getIsSupportVirtualBackground() {
    const isBrowserSupport =
      isChromeVersionHigherThan(91) || isFirefoxVersionHigherThan(89);
    return (
      navigator.hardwareConcurrency &&
      navigator.hardwareConcurrency > 2 &&
      //vb only support on high version of chrome or firefox
      isBrowserSupport &&
      typeof OffscreenCanvas == 'function' &&
      this._isSupportVirtualBackground
    );
  }

  setIsSupportVirtualBackground(bool) {
    this._isSupportVirtualBackground = !!bool;
  }

  /**
   * @description if render self video in the encode worker, other than in the decode worker or main thread.
   */
  getIsRenderSelfVideoInEncodeWorker(isSelfVideo = false) {
    return (
      isSelfVideo &&
      !this.getIsSupportMultiThread() &&
      this.getIsSupportVirtualBackground()
    );
  }
}
export const apiSupportUtility = new _ApiSupportUtility();

var HW720PEncoderCapacity = {
  capacity: undefined,
  get capacityfor720() {
    return this.capacity === undefined ? false : this.capacity;
  },
  set capacityfor720(ca) {
    this.capacity = ca;
  },
};

var HW1080PDeocderCapacity = {
  capacitydeco: undefined,
  get capacitydecofor1080() {
    return this.capacitydeco === undefined ? false : this.capacitydeco;
  },
  set capacitydecofor1080(ca) {
    this.capacitydeco = ca;
  },
};

function extractVersion(uastring, expr, pos) {
  const match = uastring.match(expr);
  return match && match.length >= pos && parseInt(match[pos], 10);
}

function detectBrowser() {
  const { navigator } = window;

  // Returned result object.
  const result = { browser: null, version: null };

  // Fail early if it's not a browser
  if (typeof window === 'undefined' || !window.navigator) {
    result.browser = 'Not a browser.';
    return result;
  }

  if (navigator.mozGetUserMedia) {
    // Firefox.
    result.browser = 'firefox';
    result.version = extractVersion(navigator.userAgent, /Firefox\/(\d+)\./, 1);
  } else if (navigator.webkitGetUserMedia) {
    // Chrome, Chromium, Webview, Opera.
    // Version matches Chrome/WebRTC version.
    result.browser = 'chrome';
    result.version = extractVersion(
      navigator.userAgent,
      /Chrom(e|ium)\/(\d+)\./,
      2
    );
  } else if (
    navigator.mediaDevices &&
    navigator.userAgent.match(/Edge\/(\d+).(\d+)$/)
  ) {
    // Edge.
    result.browser = 'edge';
    result.version = extractVersion(
      navigator.userAgent,
      /Edge\/(\d+).(\d+)$/,
      2
    );
  } else if (
    window.RTCPeerConnection &&
    navigator.userAgent.match(/AppleWebKit\/(\d+)\./)
  ) {
    // Safari.
    result.browser = 'safari';
    result.version = extractVersion(
      navigator.userAgent,
      /AppleWebKit\/(\d+)\./,
      1
    );
  } else {
    // Default fallthrough: not supported.
    result.browser = 'Not a supported browser.';
    return result;
  }

  return result;
}
const browserType = detectBrowser();

function getBrowserInfo() {
  var agent = navigator.userAgent.toLowerCase();
  var regStr_ff = /firefox\/[\d.]+/gi;
  var regStr_chrome = /chrome\/[\d.]+/gi;
  var regStrChrome2 = /ipad; cpu os (\d+_\d+)/gi;
  var regStr_saf = /safari\/[\d.]+/gi;
  var regStr_saf2 = /safari\/[\d.]+/gi;
  var regStr_edg = /edg\/[\d.]+/gi;

  // firefox
  if (agent.indexOf('firefox') > 0) {
    return agent.match(regStr_ff);
  }

  // Safari
  if (agent.indexOf('safari') > 0 && agent.indexOf('chrome') < 0) {
    var tmpInfo = 'safari/unknow';
    var tmpInfo2;
    tmpInfo = agent.match(regStr_saf);
    tmpInfo2 = agent.match(regStr_saf2);
    if (tmpInfo) {
      tmpInfo = [tmpInfo.toString().replace('version', 'safari')];
    }
    if (tmpInfo2) {
      tmpInfo = [tmpInfo2.toString().replace('version', 'safari')];
    }
    return tmpInfo;
  }

  // Chrome
  if (agent.indexOf('chrome') > 0) {
    return agent.match(regStr_chrome);
  }

  return 'other';
}

function DetectQuestBrowser() {
  try {
    return /oculusbrowser/i.test(navigator.userAgent);
  } catch (e) {
    return false;
  }
}

function DetectAndroidBrowser() {
  try {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}
function DetectisOpera65() {
  try {
    var tem = navigator.userAgent.match(/OPR\/(\d+)\./);
    if (tem && Number(tem[1]) < 66) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

function DetectChromeOS() {
  try {
    if (/\bCrOS\b/.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

function getGoogleNestVersion() {
  const googleNestUa = navigator.userAgent.match(/CrKey\/([\d.]+)/);
  if (googleNestUa) {
    const version = googleNestUa[1];
    const mainVersion = version.match(/[0-9]{0,2}[.][0-9]{0,2}/);
    return mainVersion ? mainVersion[0] : -1;
  }
  return -1;
}

function DetectIsSupportImageCapture() {
  return typeof ImageCapture === 'function';
}
var LocalIsSupportWebGLOffscreenCanvas =
  (function LocalIsSupportWebGLOffscreenCanvas() {
    if (typeof OffscreenCanvas !== 'function') {
      return false;
    }

    const ofs = new OffscreenCanvas(1, 1);
    if (ofs.getContext('webgl')) {
      return true;
    }

    return false;
  })();
export function IsSupportWebGLOffscreenCanvas() {
  return LocalIsSupportWebGLOffscreenCanvas;
}

function DetectIsGoogleNestChrome() {
  return navigator.userAgent.indexOf('CrKey') !== -1;
}

function AndroidNotGoogleNest() {
  return DetectAndroidBrowser() && !DetectIsGoogleNestChrome();
}

function DetectisSupportVideoTrackReader() {
  return (
    typeof VideoTrackReader === 'function' && typeof VideoFrame == 'function'
  );
}

export function Get_Logical_SSrc(ssrc) {
  if (ssrc) {
    return (ssrc >> 10) << 10;
  }
  return -1;
}

export default {
  isGoogleNestChrome() {
    return DetectIsGoogleNestChrome();
  },
  isHighVersionGoolgeNestChrome() {
    return (
      navigator.userAgent.indexOf('CrKey') !== -1 &&
      getGoogleNestVersion() >= 1.54
    );
  },
  /**
   * @returns {Promise<boolean>} check if local p2p connection is supported
   */
  isSupport2DCanvasDrawFrame() {
    /*Mac chrome 2d context drawing video frame have bug when camera can only capture 720p video when set 640x360 para to constraints,
         ctx.drawImage(videoframe,0,0,width,height) wil onlry draw 1/4 of captued video,
        and google nest 2d context drawing video frame increase almost 300MB GPU memory, will crush if memory is not enough*/
    return (
      (typeof MediaStreamTrackProcessor === 'function' ||
        DetectisSupportVideoTrackReader()) &&
      !DetectIsGoogleNestChrome() &&
      browserType.browser == 'chrome' &&
      browserType.version >= 90 &&
      navigator.appVersion.indexOf('Mac') == -1
    );
  },

  checkLocalP2PConnection() {
    return new Promise(async (resolve, reject) => {
      function cp(rtcIns) {
        return new Promise((resolve, reject) => {
          rtcIns.onconnectionstatechange = (state) => {
            if (rtcIns.connectionState === 'connected') {
              resolve(true);
            }
          };
        });
      }

      function close(rtcIns) {
        if (rtcIns) {
          rtcIns.close();
        }
      }

      let rtcA = null;
      let rtcB = null;
      let canvas = document.createElement('canvas');
      let offer, answer;

      try {
        canvas.width = 200;
        canvas.height = 200;
        canvas.getContext('2d'); // for firefox
        let stream = canvas.captureStream();

        if (typeof RTCPeerConnection !== 'function') {
          // very old browser
          resolve(false);
          return;
        }

        rtcA = new RTCPeerConnection();
        rtcB = new RTCPeerConnection();

        rtcA.onicecandidate = (e) => {
          e.candidate && rtcB.addIceCandidate(new RTCIceCandidate(e.candidate));
        };

        rtcB.onicecandidate = (e) => {
          e.candidate && rtcA.addIceCandidate(new RTCIceCandidate(e.candidate));
        };

        Promise.all([cp(rtcA), cp(rtcB)]).then(() => {
          // Test result : local P2P connection will be created after 1 ~ 15 ms, it depends on the user's computer/browser
          // Chrome is fastest : 1ms
          // Edge(chromium) : 8ms
          resolve(true);
          close(rtcA);
          close(rtcB);
        });

        if (typeof rtcA.addStream !== 'function') {
          // Safari doesn't implement addStream
          resolve(false);
          return;
        }

        await rtcA.addStream(stream);
        offer = await rtcA.createOffer();
        await rtcA.setLocalDescription(offer);
        await rtcB.setRemoteDescription(offer);
        answer = await rtcB.createAnswer();
        await rtcB.setLocalDescription(answer);
        await rtcA.setRemoteDescription(answer);

        setTimeout(() => {
          resolve(false); // not supported local P2P rtc peer connection
          close(rtcA);
          close(rtcB);
          // the settimeout is not stable, add from 100ms to 200ms temparary
        }, 200);
      } catch (e) {
        globalTracingLogger.error(
          'Error when trying to check for local RTC peer connection',
          e
        );
        resolve(false);
      }
    });
  },
  getDocumentHandle(id) {
    return document.getElementById(id);
  },
  /**
   * There is no 100% reliable browser detection
   * Better solution is 'browser feature detection'
   */
  browserType,
  browser: {
    isFirefox: browserType.browser === 'firefox',
    isChrome: browserType.browser === 'chrome',
    isSafari: browserType.browser === 'safari',
  },
  isIphoneOrIpadSafari() {
    return this.isIphoneOrIpadBrowser() || this.isIpadOS();
  },
  isOpera65() {
    return DetectisOpera65();
  },
  isAndroidBrowser() {
    return AndroidNotGoogleNest();
  },
  isSupportVideoFrame() {
    return typeof VideoFrame != 'undefined';
  },
  isQuestBrowser() {
    return DetectQuestBrowser();
  },
  isMobileSafariSupportVideoFrame() {
    return (
      (this.isIphoneOrIpadBrowser() ||
        (this.isIpadOS() && jsMediaEngineVariables.rwgAgent)) &&
      this.isSupportVideoFrame()
    );
  },

  isSelfPreviewRenderWithVideo() {
    if (this.isMobileSafariSupportVideoFrame()) {
      return true;
    }
    return (
      (!apiSupportUtility.getIsSupportMultiThread() &&
        !apiSupportUtility.getIsSupportVirtualBackground() &&
        this.isSupportVideoFrameOrBitmapCapture()) ||
      /** enable multithread in android for video-sdk */
      (this.isAndroidBrowser() && !jsMediaEngineVariables.rwgAgent)
    );
  },
  isIphoneOrIpadBrowser() {
    try {
      if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },
  isIpadOS() {
    return (
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      (/iPad/.test(navigator.platform) || /MacIntel/.test(navigator.platform))
    );
  },
  isChromeOS() {
    return DetectChromeOS();
  },
  isWindowsChrome() {
    const { userAgent } = navigator;
    return /windows/i.test(userAgent);
  },
  isTeslaMode() {
    return /TESLA/.test(navigator.userAgent);
  },
  isMac() {
    return navigator.platform.indexOf('Mac') > -1;
  },
  isWindows() {
    return navigator.platform.indexOf('Win') > -1;
  },
  //ChromeOS is a linux-based operating system
  isLinux() {
    return navigator.platform.indexOf('Linux') > -1 && !this.isChromeOS();
  },

  //edge is supported as chrome
  isSupportChromeWideAEC() {
    const isChromeOSSupportChromeWideAEC =
      jsMediaEngineVariables.chromeWideAEC &&
      this.isChromeOS() &&
      isChromeVersionHigherThan(116);
    return (
      ((this.isMac() || this.isWindows() || this.isLinux()) &&
        isChromeVersionHigherThan(111)) ||
      isChromeOSSupportChromeWideAEC
    );
  },

  isSupportOpenMicWhenShareAudio() {
    return (
      this.isSupportChromeWideAEC() && !jsMediaEngineVariables.shareSystemAudio
    );
  },

  /**
   * @description: Report Support Audio Feature to MMR
   * @return {int}
   */
  // return uint32
  getAudioFeatureFlags() {
    // !! important same as common defines
    const SUPPORT_FEATURE_NONE = 0x00000000;
    const SUPPORT_FEATURE_OPUS_ENCODE = 0x00000001 << 2; // both 5128 wcl and audiobridge supported
    const SUPPORT_FEATURE_LOW_LATENCY_DECODE = 0x00000001 << 3; // audio bridge not supported
    //TODO
    // old WCL : return SUPPORT_FEATURE_NONE
    // new WCL : return SUPPORT_FEATURE_OPUS_ENCODE | SUPPORT_FEATURE_LOW_LATENCY_DECODE
    // auiobridge: SUPPORT_FEATURE_OPUS_ENCODE
    if (jsMediaEngineVariables.enableAudioBridge)
      return SUPPORT_FEATURE_OPUS_ENCODE;
    else
      return SUPPORT_FEATURE_OPUS_ENCODE | SUPPORT_FEATURE_LOW_LATENCY_DECODE;
  },
  isSupportShareMultiStream() {
    return true;
  },
  isSupportVideoLTR() {
    return true;
  },
  isSupportAudioBridgeAvsync() {
    return true;
  },
  getAudioContextConfigure() {
    let audioContextConfigure = {};
    let latencyHint = 0.02;
    if (isChromimuVersionHigherThan(74)) {
      if (false && navigator.hardwareConcurrency >= 4) {
        latencyHint = 0.01;
      }
      audioContextConfigure = {
        sampleRate: 48000,
        latencyHint: latencyHint,
      };
    }
    return audioContextConfigure;
  },
  /**
   * @param url {string}
   * @param integrity {string}
   * @returns {Promise}
   */
  download(url, integrity = null) {
    if (downloadUrlMapPromise[url]) {
      return downloadUrlMapPromise[url];
    }
    let promise = new Promise((resolve, reject) => {
      let initParams = {};
      if (integrity) {
        initParams.integrity = integrity;
      }
      fetch(url, initParams)
        .then((response) => {
          resolve(response.text());
        })
        .catch((ex) => {
          reject(ex);
        });
    });
    downloadUrlMapPromise[url] = promise;
    return promise;
  },
  downloadBinary(url) {
    if (downloadUrlMapPromise[url]) {
      return downloadUrlMapPromise[url];
    }
    let promise = new Promise((resolve, reject) => {
      var oReq = new XMLHttpRequest();
      oReq.responseType = 'arraybuffer';
      oReq.addEventListener(
        'load',
        function () {
          resolve(this.response);
        },
        oReq
      );
      oReq.onerror = function (ex) {
        reject(ex);
      };
      oReq.open('get', url, true);
      oReq.send();
    });
    downloadUrlMapPromise[url] = promise;
    return promise;
  },
  /**
   * @param blob
   * @returns {Promise<String>}
   */
  readBlob: (blob) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.readAsText(blob);
    });
  },
  readBlobAsBuffer: (blob) => {
    return new Promise((resolve, reject) => {
      var fileReader = new FileReader();
      fileReader.onload = function (event) {
        resolve(event.target.result);
      };
      fileReader.readAsArrayBuffer(blob);
    });
  },
  lengthInUtf8Bytes(str) {
    // Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
    var m = encodeURIComponent(str).match(/%[89ABab]/g);
    return str.length + (m ? m.length : 0);
  },
  /**
   * @returns {Boolean} true means Little-Endian
   */
  isLittleEndian() {
    let arrayBuffer = new ArrayBuffer(2);
    let uint8Array = new Uint8Array(arrayBuffer);
    let uint16array = new Uint16Array(arrayBuffer);
    uint8Array[0] = 0xaa;
    uint8Array[1] = 0xbb;
    return uint16array[0] === 0xbbaa;
  },
  sleep(millionSeconds) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, millionSeconds);
    });
  },
  isSDKSupportMultiThread: async () => {
    await apiSupportUtility.checkIsSupportMultiThread();
    return (
      apiSupportUtility.getIsSupportMultiThread() &&
      navigator.userAgent &&
      (navigator.userAgent.indexOf('CrKey') == -1 ||
        getGoogleNestVersion() >= 1.54)
    );
  },
  is32bitChrome: async () => {
    if (navigator.userAgent.indexOf('WOW64') != -1) {
      return true;
    } else if (
      navigator.userAgentData &&
      navigator.userAgentData.getHighEntropyValues
    ) {
      let value = await navigator.userAgentData.getHighEntropyValues(['wow64']);
      return value.wow64;
    } else {
      return false;
    }
  },

  isAMDGraphic() {
    try {
      var renderer_info = this.graphicName();
      var bIsAMD = renderer_info.includes('amd');
      return bIsAMD;
    } catch (e) {
      return false;
    }
  },
  graphicName: (() => {
    let renderer_info = null;
    return () => {
      try {
        if (renderer_info) return renderer_info;
        var canvas = new OffscreenCanvas(1, 1);
        var gl = canvas.getContext('webgl');

        if (!gl) return '';
        var debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
        // var vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
        var renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        renderer_info = renderer.toLowerCase();
        return renderer_info;
      } catch (e) {
        return '';
      }
    };
  })(),

  isGraphicShouldUseHardwareAccelerationDecode() {
    // UI check HW decode option or not
    // AMD && isChromeOS() // checked
    // AMD && !isChromeOS() //unchecked
    if (this.isAMDGraphic() && this.isChromeOS()) {
      return true;
    } else if (this.isAMDGraphic() && !this.isChromeOS()) {
      return false;
    }
    return true;
  },

  //Demo : https://jsfiddle.net/b9fgkx80/
  VP9MachineDetect() {
    var mediaConfig = {
      type: 'webrtc',
      video: {
        contentType: 'video/vp9',
        bitrate: 10000000,
        framerate: 25,
        height: 1920,
        width: 1080,
      },
    };
    const decode_promise =
      navigator.mediaCapabilities.decodingInfo(mediaConfig);

    return new Promise((resolve, reject) => {
      decode_promise
        .then((info) => {
          // if we don't even have software support - chrome may not support the codec -
          // throw an error
          if (!info.supported) {
            globalTracingLogger.log(
              "video/vp9 codec isn't supported in software or hardware"
            );
            resolve(false);
          }
          // given the current chrome source, powerEfficient deconstructs into hardware acceleration available
          // https://webrtc.googlesource.com/src/+/refs/heads/main/api/video_codecs/video_decoder_factory.h#49
          resolve(info.powerEfficient);
        })
        .catch((err) => {
          globalTracingLogger.warn(
            'Error when trying to determine support for VP9 codec',
            err
          );
          resolve(false);
        });
    });
  },

  async IsSupportVideoDecodeHardwareAcceleration() {
    if (typeof VideoDecoder === 'function' && !this.browser.isSafari) {
      var extradata_info = new Uint8Array([
        1, 100, 0, 31, 255, 225, 0, 14, 103, 100, 0, 51, 172, 27, 26, 17, 129,
        64, 22, 201, 160, 16, 7, 0, 5, 104, 200, 66, 60, 48, 0, 5, 104, 82, 16,
        207, 12, 0, 25, 104, 114, 16, 143, 24, 67, 17, 132, 56, 140, 84, 81, 8,
        18, 22, 41, 3, 194, 98, 3, 5, 32, 122, 9, 140, 0, 5, 104, 36, 132, 51,
        203, 0, 5, 104, 46, 132, 51, 195, 0, 25, 104, 54, 132, 35, 198, 16, 196,
        97, 14, 35, 21, 20, 66, 4, 133, 138, 64, 240, 152, 128, 193, 72, 30,
        130, 99, 0, 5, 104, 62, 132, 51, 203,
      ]);

      var config = {
        codec: 'avc1.640028',
        description: extradata_info,
        codedWidth: 1280,
        codedHeight: 720,
        hardwareAcceleration: 'prefer-hardware',
      };
      try {
        let result = await VideoDecoder.isConfigSupported(config);
        if (result.supported) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }
  },
  async IsSupportVideoEncodeHardwareAcceleration() {
    if (typeof VideoEncoder === 'function' && !this.browser.isSafari) {
      let avcConfig = { format: 'annexb' };
      var config = {
        codec: 'avc1.640028',
        bitrate: 1500000,
        width: 1280,
        height: 720,
        avc: avcConfig,
        framerate: 25,
        hardwareAcceleration: 'no-preference',
        latencyMode: 'realtime',
        bitrateMode: 'constant',
        scalabilityMode: 'L1T2',
      };

      let result = await VideoEncoder.isConfigSupported(config);
      if (result.supported) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },

  AdapterWhiteListCheckForEncoder() {
    if (!IsSupportWebGLOffscreenCanvas()) {
      return -1;
    }
    try {
      var canvas = new OffscreenCanvas(1, 1);
      var gl = canvas.getContext('webgl');

      var debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      var vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
      var renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);

      var renderer_info = renderer.toLowerCase();

      var bIsARM = vendor.includes('ARM');
      var bIsAMD = renderer_info.includes('amd');

      var bIsIntel = renderer_info.includes('intel');
      var bIsHdGpu = renderer_info.includes('hd graphics');

      var bIsNvidia = renderer_info.includes('nvidia');
      var bIsGeForce = renderer_info.includes('geforce');

      if (bIsIntel) {
        return 0;
      }
      if (bIsARM) {
        return -1;
      }
      if (bIsAMD) {
        return 0;
      }
      if (bIsNvidia) {
        return 0;
      }
      return 0;
    } catch (e) {
      return 0;
    }
  },
  isChromeVersionHigherThan,
  isFirefoxVersionHigherThan,
  async isSDKSupportSIMD() {
    if (typeof WebAssembly !== 'object') return false;
    if (browserType.browser == 'chrome') {
      // for chrome, we only detect simd-support if version >= 84
      if (browserType.version >= 84) {
        await apiSupportUtility.checkIsSupportSIMD();
      } else {
        return false;
      }
    } else {
      await apiSupportUtility.checkIsSupportSIMD();
    }
    return apiSupportUtility.getIsSupportSIMD();
  },
  buffer2stringSplitByComma(buffer) {
    let ints = new Uint8Array(buffer);

    return ints.join(',');
  },
  stringSplitByComma2Buffer(str) {
    try {
      let arr = str.split(',').map((item) => parseInt(item));
      let buffer = new ArrayBuffer(arr.length);
      let ints = new Uint8Array(buffer);

      for (let i = 0; i < arr.length; i++) {
        ints[i] = arr[i];
      }

      return buffer;
    } catch (e) {
      return null;
    }
  },
  removeDuplicates(list, diffCheckFunction) {
    let newList = [];
    list.forEach((item) => {
      let isDup = false;
      for (let i = 0; i < newList.length; i++) {
        if (!diffCheckFunction.call(null, newList[i], item)) {
          isDup = true;
          break;
        }
      }

      if (!isDup) {
        newList.push(item);
      }
    });

    return newList;
  },

  getBrowserVersion() {
    return getBrowserInfo()[0].match(/(\d+)/)[0];
  },
  getIOSMajorVersion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      let v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      return parseInt(v[1], 10);
    }
    return null;
  },
  getMacSafariMajorVersion() {
    if (this.browser.isSafari) {
      const v = navigator.userAgent.match(/Version\/([\d\.]+)/);
      return v && v[1] ? parseFloat(v[1]) : null;
    }
    return null;
  },
  isSupportImageCapture() {
    return DetectIsSupportImageCapture() && IsSupportWebGLOffscreenCanvas();
  },
  isSupportOffscreenCanvas() {
    return IsSupportWebGLOffscreenCanvas();
  },
  isSupport2dOffscreenCanvas() {
    return typeof OffscreenCanvas === 'function';
  },

  isSupportVideoTrackReader() {
    return DetectisSupportVideoTrackReader() && IsSupportWebGLOffscreenCanvas();
  },
  isSupportMediaStreamTrackProcessor,
  isSupportVideoFrameOrBitmapCapture() {
    return (
      this.isSupportImageCapture() ||
      this.isSupportVideoTrackReader() ||
      this.isSupportMediaStreamTrackProcessor()
    );
  },
  isSupportSharedArrayBuffer() {
    return typeof SharedArrayBuffer !== 'undefined';
  },
  async queryPTZPermisson() {
    try {
      const panTiltZoomPermissionStatus = await navigator.permissions.query({
        name: 'camera',
        panTiltZoom: true,
      });

      if (panTiltZoomPermissionStatus.state == 'granted') {
        return true;
      }

      return false;
    } catch (error) {
      globalTracingLogger.error(
        'Error when querying pan-tilt-zoom permission',
        error
      );
      return false;
    }
  },
  isSupportCameraPan() {
    return !!navigator.mediaDevices.getSupportedConstraints().pan;
  },
  isSupportCameraTilt() {
    return !!navigator.mediaDevices.getSupportedConstraints().tilt;
  },
  isSupportCameraZoom() {
    return !!navigator.mediaDevices.getSupportedConstraints().zoom;
  },
  isSupportPTZ() {
    // if support pan tilt zoom
    const mediaContraints = navigator.mediaDevices.getSupportedConstraints();
    return mediaContraints.pan && mediaContraints.tilt && mediaContraints.zoom;
  },

  get720pcapacity() {
    return HW720PEncoderCapacity.capacityfor720;
  },
  set720pcapacity(ca) {
    HW720PEncoderCapacity.capacityfor720 = ca;
  },

  getsub1080pcapacity() {
    return HW1080PDeocderCapacity.capacitydecofor1080;
  },
  setsub1080pcapacity(ca) {
    HW1080PDeocderCapacity.capacitydecofor1080 = ca;
  },

  checkAudioAutoPlay() {
    /**
     * Request access to navigator.mediaDevices.getUserMedia,
     *   if user allowed or remembered allowed, then audio can auto play
     *
     * In firefox, if user check remembered allow choice,
     *      the prompt window won't be opened after getUserMedia
     *
     * if user never check remember choice when getUserMedia,
     *      the prompt window will be always opened after getUserMedia.
     *
     *  Firefox has three types of permission controls
     *      1. auto play
     *      2. audio capture
     *      3. video capture
     *
     * In Chrome, if user already allow audio access, (no remember checkbox),
     *      the prompt window won't be opened after getUserMedia.
     *      (But it is not sure, it's based on limited tests)
     *
     * In Edge Chromium, if user already allow audio access, (no remember checkbox),
     *      the prompt window won't be opened after getUserMedia.
     *
     *      Important : Although disable microphone access,audio can auto play. (different from Chrome)
     *
     *
     * Tip : How to reset media-engagement score(in Chrome/Edge)?
     *      Open a guest browser window.
     */
    return new Promise((resolve, reject) => {
      //create a wav audio(16K, 16bits, 0.01s, silence audio)
      let audioArrayBuffer = new ArrayBuffer(684);
      let audioInt32 = new Uint32Array(audioArrayBuffer);
      let header = [
        1179011410, 676, 1163280727, 544501094, 16, 65539, 16000, 64000,
        2097156, 1635017060, 640,
      ];
      audioInt32.set(header, 0);

      let blob = new Blob([audioInt32], { type: 'audio/wav' });
      let blobURL = window.URL.createObjectURL(blob);
      let audio = new Audio(blobURL);

      audio.addEventListener('canplaythrough', () => {
        audio
          .play()
          .then(() => {
            resolve(true);
          })
          .catch((ex) => {
            globalTracingLogger.log('Unable to auto play audio', ex);
            reject(ex);
          })
          .finally(() => {
            window.URL.revokeObjectURL(blobURL);
          });
      });

      /** iphone safari need this to trigger canplaythrouth event */
      if (audio.load) {
        audio.load();
      }
    });
  },

  isSupportVideoShare() {
    return true;
  },

  isSupportVideoShareSend() {
    let result = detectBrowser();
    let version;
    if (result.browser == 'chrome') {
      version = this.getBrowserVersion();
    }

    if (!this.isSupportSharedArrayBuffer()) return false;

    if (
      result.browser !== 'chrome' ||
      (version && version <= 100) ||
      navigator.hardwareConcurrency <= 2 ||
      this.isTeslaMode() ||
      this.isAndroidBrowser() ||
      this.isIphoneOrIpadBrowser() ||
      this.isGoogleNestChrome()
    ) {
      return false;
    }
    return true;
  },

  isSupportVideoShareReceive() {
    return true;
  },

  /**
   * @description provided to ui to call this fro getting same
   */
  watermark: (() => {
    const WaterMarkRGBA = new JsMediaSDK_WaterMarkRGBA();
    const waterMarkCanvas = document.createElement('canvas');
    const watermarkInfo = {
      enableWaterMark: false,
      waterMarkText: '',
      watermarkOpacity: 0,
      watermarkRepeated: false,
      watermarkPosition: undefined,
    };
    const getHigherQualitySize = function (width, height) {
      if (width < 640 && width) {
        const zoom = 640 / width;
        width = 640;
        height = Math.round(height * zoom);
      }
      return { width, height };
    };
    return {
      getWaterMarkData({ width, height }) {
        if (!watermarkInfo.enableWaterMark) return null;
        /** follow native client, if video size too small, always render watermark in middle */
        const position =
          width < 512 || height < 288 ? 16 : watermarkInfo.watermarkPosition;
        const shouldRepeated =
          watermarkInfo.watermarkRepeated && width > 306 && height > 202;
        const highQualitySize = getHigherQualitySize(width, height);
        width = highQualitySize.width;
        height = highQualitySize.height;

        const watermarkData = shouldRepeated
          ? WaterMarkRGBA.Get_Repeated_WaterMarkRGBA({
              canvas: waterMarkCanvas,
              name: watermarkInfo.waterMarkText,
              width: highQualitySize.width,
              height: highQualitySize.height,
              opacity: watermarkInfo.watermarkOpacity,
              position,
              convertToDataUrl: true,
            })
          : WaterMarkRGBA.Get_WaterMarkRGBA({
              canvas: waterMarkCanvas,
              name: watermarkInfo.waterMarkText,
              width: highQualitySize.width,
              height: highQualitySize.height,
              opacity: watermarkInfo.watermarkOpacity,
              position,
              convertToDataUrl: true,
            });
        return watermarkData;
      },
      updateWaterMarkInfo(params) {
        Object.assign(watermarkInfo, params);
      },
    };
  })(),

  isSupportAudioDenoise() {
    // TODO: denoise need support simd

    let result = detectBrowser();
    if (result.browser == 'chrome') {
      let version = this.getBrowserVersion();
      if (version <= 100) return false;
    }
    const supportSIMD = WebAssembly.validate(
      new Uint8Array([
        0, 97, 115, 109, 1, 0, 0, 0, 1, 4, 1, 96, 0, 0, 3, 2, 1, 0, 10, 9, 1, 7,
        0, 65, 0, 253, 15, 26, 11,
      ])
    );

    /*
    https://github.com/GoogleChromeLabs/wasm-feature-detect#contributing
    WebAssembly.validate is sync now. But it's possible to be async in the future
    */
    if (!supportSIMD || supportSIMD instanceof Promise) {
      if (supportSIMD instanceof Promise)
        console.error('WebAssembly.validate is async');
      return false;
    }

    if (!this.isSupportSharedArrayBuffer()) return false;

    if (
      navigator.hardwareConcurrency <= 2 ||
      this.isTeslaMode() ||
      this.isAndroidBrowser() ||
      this.isIphoneOrIpadBrowser() ||
      this.isGoogleNestChrome()
    ) {
      return false;
    }
    return true;
  },

  //stereo can't use peerConnection do AEC
  isSupportPlayStereo() {
    return this.browser.isFirefox ||
      this.browser.isSafari ||
      this.isSupportChromeWideAEC()
      ? true
      : false;
  },

  //worklet get stereo audio need close AEC/AGC/NS
  isSupportMicSendStereo() {
    return false;
  },

  //mac chrome will get noise if join computer audio when share stereo audio
  //return false now
  isSupportSharingStereo() {
    return false;
  },

  videoToMediaStreamManager: (() => {
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    let _video;
    let _width = 640;
    let _height = 360;
    let _stream;
    const frameRate =
      navigator.hardwareConcurrency && navigator.hardwareConcurrency < 4
        ? 10
        : 24;
    /** chrome capture stream framerate will be low if canvas don't draw new frame */
    const maxFrameDuration =
      1000 / (browserType.browser === 'chrome' ? frameRate : 10);
    /** chrome timeupdate too low, need to manual draw faster */
    let frameRateControlTimer = null;
    let lastTimeStamp = 0;
    let updateFnHanlder = null;
    return {
      _draw() {
        if (!_video) return;
        this.drawCanvas(canvas, ctx, _width, _height);
        const now = performance.now();
        if (lastTimeStamp) {
          const duration = now - lastTimeStamp;
          if (duration > maxFrameDuration) {
            this._setFasterTimer();
          } else if (duration < maxFrameDuration * 0.8) {
            this._clearFastTimer();
          }
        }
        lastTimeStamp = now;
      },
      _setFasterTimer() {
        if (frameRateControlTimer) return;
        frameRateControlTimer = setInterval(() => {
          if (
            _video &&
            _video.currentTime > 0 &&
            !_video.paused &&
            !_video.ended &&
            performance.now() - lastTimeStamp >= maxFrameDuration / 2
          ) {
            this.drawCanvas(canvas, ctx, _width, _height);
          }
        }, maxFrameDuration);
      },
      _clearFastTimer() {
        lastTimeStamp = 0;
        clearInterval(frameRateControlTimer);
        frameRateControlTimer = null;
      },
      drawCanvas(canvas, ctx, width, height) {
        canvas.width = width;
        canvas.height = height;
        let displayWidth = 0;
        let displayHeight = 0;
        if (_video.videoWidth / _video.videoHeight > width / height) {
          displayHeight = _video.videoHeight;
          displayWidth = (width / height) * displayHeight;
        } else {
          displayWidth = _video.videoWidth;
          displayHeight = (height / width) * displayWidth;
        }
        ctx.drawImage(
          _video,
          (_video.videoWidth - displayWidth) / 2,
          (_video.videoHeight - displayHeight) / 2,
          displayWidth,
          displayHeight,
          0,
          0,
          width,
          height
        );
      },
      startCapture(video, width, height) {
        _video = video;
        if (width && height && width / height === 16 / 9) {
          _width = width;
          _height = height;
        }
        if (updateFnHanlder) {
          _video.removeEventListener('timeupdate', updateFnHanlder);
        }
        updateFnHanlder = this._draw.bind(this);
        video.addEventListener('timeupdate', updateFnHanlder);
        _stream = canvas.captureStream(frameRate);
        return _stream;
      },
      stopCapture() {
        if (updateFnHanlder) {
          _video.removeEventListener('timeupdate', updateFnHanlder);
        }
        this._clearFastTimer();
        if (_stream) {
          _stream.getVideoTracks().forEach((track) => track.stop());
          _stream = null;
        }
        _video = null;
      },
      isSupported() {
        return typeof HTMLCanvasElement.prototype.captureStream === 'function';
      },
    };
  })(),
  audioToMediaStreamMananger: (() => {
    let _audio = null;
    let _audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    let _stream = null;
    /** chrome has bug: https://bugs.chromium.org/p/chromium/issues/detail?id=851310
     * when audio tag connect to a audioContext, the binding relationship can't be destroy,
     * so we should cache audio tag with audioContext
     */
    let _elementSource = null;
    let _previousAudio = null;
    return {
      startCapture(audio) {
        if (_previousAudio && audio === _previousAudio) {
          _audio = _previousAudio;
        } else {
          this.destroy();
          _audio = audio;
          _previousAudio = _audio;
          _elementSource = _audioContext.createMediaElementSource(_audio);
          const destination = _audioContext.createMediaStreamDestination();
          _elementSource.connect(destination);
          _stream = destination.stream;
        }
        if (_audioContext.state === 'suspended') {
          _audioContext.resume();
        }
        return _stream;
      },
      stopCapture() {
        if (_audioContext) {
          _audioContext.suspend();
        }
        _audio = null;
      },
      /** destroy ref, otherwise gc can't clear audio tag ref */
      destroy() {
        if (_elementSource) {
          _elementSource.disconnect();
        }
        if (_stream) {
          _stream.getAudioTracks().forEach((track) => track.stop());
          _stream = null;
        }
        _previousAudio = null;
        _elementSource = null;
      },
      isAudioFileStream(stream) {
        return stream === _stream;
      },
      isSupported() {
        return (
          typeof AudioContext !== 'undefined' &&
          typeof AudioContext.prototype.createMediaStreamDestination ===
            'function' &&
          typeof AudioContext.prototype.createMediaElementSource === 'function'
        );
      },
    };
  })(),
};

export function Deferred() {
  let that = this;
  this.promise = new Promise(function (resolve, reject) {
    that.reject = reject;
    that.resolve = resolve;
  });
}

export function getFilename(url) {
  return url.split('/').pop().split('?')[0].split('#')[0];
}

/**
 * Subresource Integrity (SRI) is a security feature that enables browsers to verify that resources
 * they fetch (for example, from a CDN) are delivered without unexpected manipulation.
 * @link https://developer.mozilla.org/en-US/docs/Web/Security/Subresource_Integrity
 */
export class IntegrityHelper {
  /**
   * @param scriptURL {string}
   * @param lateLoadedAssetsHash {{}}
   */
  constructor(scriptURL, lateLoadedAssetsHash) {
    this.scriptURL = scriptURL;
    this.lateLoadedAssetsHash = lateLoadedAssetsHash;
  }

  /**
   * @returns {string|null}
   */
  getIntegrity() {
    let filename = getFilename(this.scriptURL);
    let integrity = this.lateLoadedAssetsHash[filename];
    if (integrity) {
      return integrity;
    } else {
      return null;
    }
  }
}

export function getGcd(a, b) {
  let max = Math.max(a, b);
  let min = Math.min(a, b);
  if (max % min === 0) {
    return min;
  } else {
    return getGcd(max % min, min);
  }
}

export function getLcm(a, b) {
  return (a * b) / getGcd(a, b);
}

export function AdjustRegion_AspectRatio(
  ratioWidth,
  ratioHeight,
  alignX,
  alignY,
  alignWidth,
  alignHeight,
  urc
) {
  if (0 == ratioWidth || 0 == ratioHeight) return false;
  if (0 != alignWidth) {
    let lcm = getLcm(ratioWidth, alignWidth);
    let m = lcm / ratioWidth;
    ratioWidth = lcm;
    ratioHeight *= m;
  }
  if (0 != alignHeight) {
    let lcm = getLcm(ratioHeight, alignHeight);
    let m = lcm / ratioHeight;
    ratioHeight = lcm;
    ratioWidth *= m;
  }
  if (urc.width < ratioWidth || urc.height < ratioHeight) return false;

  let iw = urc.width / ratioWidth;
  let ih = urc.height / ratioHeight;
  let factor = iw < ih ? iw : ih;
  let ulAdjustedWidth = ratioWidth * factor;
  let ulAdjustedHeight = ratioHeight * factor;
  let ulAdjustedX = urc.x + (urc.width - ulAdjustedWidth) / 2;
  let ulAdjustedY = urc.y + (urc.height - ulAdjustedHeight) / 2;
  if (0 != alignX) ulAdjustedX -= ulAdjustedX % alignX;
  if (0 != alignY) ulAdjustedY -= ulAdjustedY % alignY;
  let urcAdjusted = {};
  urcAdjusted.left = ulAdjustedX;
  urcAdjusted.top = ulAdjustedY;
  urcAdjusted.width = ulAdjustedWidth;
  urcAdjusted.height = ulAdjustedHeight;
  return urcAdjusted;
}

export function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  } else if (isObject(obj1) && isObject(obj2)) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (var prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false;
      }
    }
    return true;
  }

  // Private
  function isObject(obj) {
    if (typeof obj === 'object' && obj != null) {
      return true;
    } else {
      return false;
    }
  }
}
